























































import { Resource } from '@/models/booked/Resource';
import { computed, defineComponent, PropType, ref, watch } from '@/plugins/composition';
import SlotCalendarRow from './SlotCalendarRow.vue';
import Vue from 'vue';
import { useBookedSchedules } from '@/composables/useBookedResources';
import BaseButton from '../base/BaseButton.vue';
import { useCreatingBooking, hasConflicts, hasAvailableSlots } from '@/composables/useCreatingBooking';
import getHours from 'date-fns/fp/getHours/index.js';

export default defineComponent({
  components: { SlotCalendarRow, BaseButton },
  name: 'ResourceCalendar',
  props: {
    resource: {
      type: Object as PropType<Resource>,
      required: true,
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
    guest: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const currentHour = ref<
      | (Vue &
          {
            $el: HTMLElement;
          }[])
      | null
    >(null);
    const calendar = ref<HTMLElement | null>(null);

    const available = computed(() => {
      return hasAvailableSlots(props.resource) && !hasConflicts(props.resource);
    });

    const resources = ref<Resource[]>([]);

    const scrollToCurrentHour = () => {
      const calendarOffset = calendar.value?.offsetLeft;
      const currentHourRef = currentHour.value && currentHour.value.length > 0 ? currentHour.value[0] : null;
      const currentHourOffset = currentHourRef ? currentHourRef.$el.offsetLeft : 0;

      if (calendarOffset && currentHourOffset && calendar.value) {
        calendar.value.scrollLeft = currentHourOffset - calendarOffset - 50;
      }
    };

    watch([calendar, currentHour], () => {
      scrollToCurrentHour();
    });

    const { groupSlots } = useBookedSchedules();

    const { repeatingType, isRepeating, currentResource, repeatingOptions } = useCreatingBooking();

    watch(
      () => repeatingOptions.value.startTime,
      () => {
        scrollToCurrentHour();
      }
    );

    const selectResource = () => {
      currentResource.value = props.resource;
    };

    const formatHour = (hour: number) => {
      if (hour === 12) {
        return '12pm';
      }
      if (hour === 0) {
        return '12am';
      }

      return hour > 12 ? hour - 12 + 'pm' : hour + 'am';
    };

    const startHour = computed(() => {
      return getHours(repeatingOptions.value.startTime);
    });

    return {
      resources,
      groupSlots,
      formatHour,
      calendar,
      currentHour,
      scroll: scrollToCurrentHour,
      emit,
      repeatingType,
      isRepeating,
      available,
      startHour,
      selectResource,
    };
  },
});
